<template>
    <div id="mianBigBox" class="clear"  :class="isActiveHead==true ? 'hideSidebar' : 'openSidebar'">
        <div class="left_nav">
            <el-container>
            <el-aside  :style="{width:isCollapse?'auto':'202px'}">

                <el-menu :collapse-transition="false"  :unique-opened="true" :router="true" :default-active="$route.path" class="el-menu-vertical-demo" :collapse="isCollapse">
                    <el-submenu v-auth="'TRADE:RECEIPT'" index="1">
                      <template slot="title">
                          <span slot="title">收款管理</span>
                      </template>
                      <el-menu-item v-auth="'TRADE:RECEIPT:ORDERS'" index="/deal" >交易查询</el-menu-item>
                      <el-menu-item v-auth="'TRADE:RECEIPT:REFUNDS'" index="/refund_tkcx" >退款查询</el-menu-item>
                      <el-menu-item v-auth="'TRADE:RECEIPT:REFUND_CHECK'" index="/refund_tksh" >退款审核</el-menu-item>
                    </el-submenu>
                    <el-submenu v-auth="'TRADE:PAY'" index="2">
                        <template slot="title">
                            <span slot="title">付款管理</span>
                        </template>
                        <el-menu-item v-auth="'TRADE:PAY:ACCOUNTS'" index="/another_df">账户列表</el-menu-item>
                        <el-menu-item v-auth="'TRADE:PAY:ORDERS'" index="/paidquery_dfcx">代付查询</el-menu-item>
                    </el-submenu>
                    <el-submenu v-auth="'TRADE:ALLOC'" index="3">
                        <template slot="title">
                            <span slot="title">分账管理</span>
                        </template>
                        <el-menu-item v-auth="'TRADE:ALLOC:ACCOUNTS'" index="/account_zhgl">账户管理</el-menu-item>
                        <el-menu-item v-auth="'TRADE:ALLOC:ORDERS'" index="/account_fzjy">分账交易查询</el-menu-item>
                        <el-menu-item v-auth="'TRADE:ALLOC:REFUNDS'" index="/account_fztk">分账退款查询</el-menu-item>
                        <el-menu-item v-auth="'TRADE:ALLOC:REFUNDSPAY'" index="/account_fzdf">分账代付查询</el-menu-item>
                        <el-menu-item v-auth="'TRADE:ALLOC:MANUAL_SETTLES'" index="/refund_sgjs">手工结算查询</el-menu-item>
                        <el-menu-item v-auth="'TRADE:ALLOC:MANUAL_SGJSSH'" index="/refund_sgjssh">手工结算审核</el-menu-item>
                        <el-menu-item v-auth="'TRADE:ALLOC:AUTO_SETTLES'" index="/automatic_zdjs">自动结算查询</el-menu-item>
                        <el-menu-item v-auth="'TRADE:ALLOC:GUARANTEE_ORDERS'" index="/guarantee_dbcx">担保查询</el-menu-item>
                        <el-menu-item v-auth="'TRADE:ALLOC:TRANSFER_ORDERS'" index="/transfer_zhzz">账户转账查询</el-menu-item>
                    </el-submenu>

                    <el-submenu v-auth="'ELECTRON:BOOK'" index="4">
                      <template slot="title">
                        <span slot="title">电子账簿管理</span>
                      </template>
                      <el-menu-item v-auth="'ELECTRON:BOOK:INFO'" index="/electronInfo">电子账簿列表</el-menu-item>
                      <el-menu-item v-auth="'ELECTRON:BOOK:DEPOSIT'" index="/deposit_rjcx">入金查询</el-menu-item>
                      <el-menu-item v-auth="'ELECTRON:BOOK:GILDWD'" index="/gildwwd_cjcx">出金查询</el-menu-item>
                    </el-submenu>
                    
                    <el-submenu v-auth="'TRADE:CUSTOMS'" index="5">
                        <template slot="title">
                            <span slot="title">跨境</span>
                        </template>
                        <el-menu-item v-auth="'TRADE:CUSTOMS:ORDERS'" index="/customs">海关推单查询</el-menu-item>
                    </el-submenu>
                </el-menu>
            </el-aside>
            </el-container>
        </div>
        <div class="right_con" >
            <div class="right_con_nav">
                <el-tabs v-model="activeTab" closable @tab-click="clickTab" @tab-remove="removeTab">
                  <el-tab-pane
                    v-for="item in tabs"
                    :key="item.fullPath"
                    :label="item.title"
                    :name="item.fullPath"
                  >
                  </el-tab-pane>
                </el-tabs>
								<div class="clearBt" @click="clearTab">
									<i class="el-icon-delete"></i>
								</div>
            </div>
            <div class="right_con_con">
                <!-- <div class="title" v-if="$route.meta.title">{{$route.meta.title}}</div> -->
                <router-view :key="$route.fullPath"></router-view>
            </div>
            
        </div>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        activeTab: '',
        tabs: [],
        isCollapse:false,
        isActiveHead:this.$store.state.isActiveHead,
      }
    },
    created() {
      this.tabs = JSON.parse(sessionStorage.getItem('tabs'));
      this.activeTab = sessionStorage.getItem('active_tab');
      var localValue;
      this.bus.$on('isActiveHead', res => {
          localValue = res;//当bus触发时，这里生效，将sendNavText值修改了，但是，当点开该页面路由组件的时候，data函数初始化了sendNavText值
          this.$store.state.isActiveHead = res; //(1)将该值存在store中
          this.isActiveHead = res;
      })
    },
    watch: {
      $route: {
        handler: function(){
          this.tabs = JSON.parse(sessionStorage.getItem('tabs'));
          this.activeTab = sessionStorage.getItem('active_tab')
        },
        // 深度观察监听
        deep: true
      }
    },
    methods: {
      clickTab(tab){
        this.$router.push(tab.name)
      },
      removeTab(targetName) {
				if(this.tabs.length==1){
					return;
				}
				this.tabs.forEach((v,i)=>{
				  if(v.fullPath === targetName){
				    if(targetName === this.activeTab){
							if(i!==0){
								this.tabs.splice(i,1)
								sessionStorage.setItem('active_tab',this.tabs[i-1].fullPath)
								this.$router.push(this.tabs[i-1].fullPath)
							}else{
								this.tabs.splice(i,1)
								sessionStorage.setItem('active_tab',this.tabs[0].fullPath)
								this.$router.push(this.tabs[0].fullPath)
							}
				    }else{
							this.tabs.splice(i,1)
						}
				    sessionStorage.setItem('tabs',JSON.stringify(this.tabs))
				    return
				  }
				})
      },
			// 清除tab
			// 清除tab
			clearTab(){
				this.$confirm('确认清空吗?', '清空', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.tabs = [{
						title:this.$route.meta.tab,
						fullPath:this.$route.fullPath
					}]
					sessionStorage.setItem('tabs',JSON.stringify(this.tabs))
					sessionStorage.setItem('active_tab',this.tabs[0].fullPath)
					this.$message({
						type: 'success',
						message: '清空成功',
            duration:2000
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消清空'
					});
				});
			}
    }
  }
</script>

<style scoped>
@import '../../assets/css/index.css';
</style>>